import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PersonIcon } from './PersonIcon';


// MUI
import { makeStyles, withStyles } from '@mui/styles';

// MUI Components
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


// MUI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function prepareName(obj, number, include_number=true) {
	if (obj === undefined) {
		return number;
	}
	else {
		var name = obj['name'];
		if (include_number) {
			name += " (" + number + ")";
		}
		return name;
	}
}

function prepareInitials(obj, number) {
	if (obj === undefined) {
		return number.slice(-2);
	}
	else {
		return obj['initials'];
	}
}



const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%',
  }
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(thread_id, numbers) {
  return {
    thread_id,
    numbers,
    details: [
      { field: 'Thread ID', value: thread_id },
      { field: "Numbers", value: numbers},
      
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

	var link_item = <Link to={"/thread/" + row.thread_id + "?view_as=" + row.numbers[0]} color="inherit">{row.thread_id}</Link>

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
					{link_item}

        </TableCell>
        <TableCell align="left">
	
				 <ButtonGroup color="primary" aria-label="outlined primary button group" variant="text">
					{row.numbers.map((numberItem) => (
						<Button>
						<Link to={"/thread/" + row.thread_id + "?view_as=" + numberItem} color="inherit">
						<PersonIcon
							info={prepareName(numberItem, numberItem)}
							initials={prepareInitials(numberItem, numberItem)}

							owner={props.owner}
						/>
						</Link>
						</Button>
					))}
      </ButtonGroup>
				</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box px={12}>
              <Table size="small" aria-label="details">
                <TableBody>
                  {row.details.map((detailsRow) => (
                    <TableRow key={detailsRow.field}>
                      <TableCell component="th" scope="row">
                        {detailsRow.field}
                      </TableCell>
                        {detailsRow.link && <TableCell><a href={detailsRow.link}>{detailsRow.value}</a></TableCell>}
                        {(!detailsRow.link) && <TableCell>{detailsRow.value}</TableCell>}   
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    thread_id: PropTypes.string.isRequired,
    numbers: PropTypes.array.isRequired,
  }).isRequired,
};



export default function CollapsibleTable(props) {
	const [rows, setRows] = React.useState([]);

  var buildRows = (search_numbers) => {
    var api_request = new XMLHttpRequest();
    api_request.addEventListener('load', function () {
      var request_return_value = api_request.status === 200 ? JSON.parse(this.responseText) : { 'threads': [] };

      var tRows = [];
      for (const element of request_return_value.threads) {
        tRows.push(
            createData(
                element.thread_id,
                element.numbers,
                )
        );
      }

      setRows(tRows);
    });

		const numbers = search_numbers.join(',')
    api_request.open('GET', 'https://api.txtifier.com/v1/messages/thread_contains/' + numbers );
    api_request.send();
  };

  useEffect(() => {
    buildRows(props.search);
  }, [props]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Thread ID</TableCell>
            <TableCell align="Left">Participants</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.thread_id} row={row}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export const SearchThreadResultsTable = withStyles(styles)(CollapsibleTable);
