import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Conversation from './Conversation';
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Landing() {
  const classes = useStyles();

	const currentTime = new Date();
	var firstTime = new Date();
	firstTime.setHours(firstTime.getHours() - 2);

	const data = {
		'first' : firstTime.toISOString(),
		'last' : currentTime.toISOString(),
		'participants' : [
			['Txtifier.com', 'Txt', '555-5555'],
			['Me', 'Me', '867-5309']
		],
		'messages' : [
			{
				'content' : [
					{
						"attachment_name": null,
						"id": "1",
						"message_id": "1",
						"thread_id": "-1",
						"date": firstTime.toISOString(),
						"sender": "555-5555",
						"recipients": [
							"867-5309"
						],
						"attachments": 0,
						"priority": null,
						"body": "Welcome to Txtifier.com",
						"initials": 'Txt',
						"name": 'Txtifier.com',
						"is_sender": false
					}
				],
				'meta' : {
					'initials' : 'Txt',
					'name' : 'Txtifier.com',
					'shows_timestamp' : true,
					'view_as' : [
						'867-5309'
					]
				}
			},
			{
				'content' : [
					{
						"attachment_name": null,
						"id": "2",
						"message_id": "2",
						"thread_id": "-1",
						"date": firstTime.toISOString(),
						"sender": "867-5309",
						"recipients": [
							"555-5555"
						],
						"attachments": 0,
						"priority": null,
						"body": "What is this site?",
						"initials": 'Me',
						"name": 'Me',
						"is_sender": true
					}
				],
				'meta' : {
					'initials' : 'Me',
					'name' : 'Me',
					'shows_timestamp' : false,
					'view_as' : [
						'867-5309'
					]
				}
			},
      { 
        'content' : [
          { 
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": firstTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "This site hosts text messages that are obtained from public records requests",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          },
          { 
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": firstTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "Public records are sometimes difficult to obtain, and hard to read, so this site makes it easy to see records that have already been obtained in an easy to read format",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          }
        ],
        'meta' : {
          'initials' : 'Txt',
          'name' : 'Txtifier.com',
          'shows_timestamp' : false,
          'view_as' : [
            '867-5309'
          ]
        }
      },
      { 
        'content' : [
          { 
            "attachment_name": null,
            "id": "2",
            "message_id": "2",
            "thread_id": "-1",
            "date": firstTime.toISOString(),
            "sender": "867-5309",
            "recipients": [
              "555-5555"
            ],
            "attachments": 0,
            "priority": null,
            "body": "How could text messages be hard to read?",
            "initials": 'Me',
            "name": 'Me',
            "is_sender": true
          }
        ],
        'meta' : {
          'initials' : 'Me',
          'name' : 'Me',
          'shows_timestamp' : false,
          'view_as' : [
            '867-5309'
          ]
        }
      },
      {
        'content' : [
          {
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": firstTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "Records usually come as spreadsheets",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          },
					{
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": firstTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "With text messages, you might have multiple conversations at one time",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          },
          {
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": firstTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "They end up all jumbled",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          }
        ],
        'meta' : {
          'initials' : 'Txt',
          'name' : 'Txtifier.com',
          'shows_timestamp' : false,
          'view_as' : [
            '867-5309'
          ]
        }
      },
      { 
        'content' : [
          { 
            "attachment_name": null,
            "id": "2",
            "message_id": "2",
            "thread_id": "-1",
            "date": currentTime.toISOString(),
            "sender": "867-5309",
            "recipients": [
              "555-5555"
            ],
            "attachments": 0,
            "priority": null,
            "body": "Great, how do I get started?",
            "initials": 'Me',
            "name": 'Me',
            "is_sender": true
          }
        ],
        'meta' : {
          'initials' : 'Me',
          'name' : 'Me',
          'shows_timestamp' : true,
          'view_as' : [
            '867-5309'
          ]
        }
      },
      {
        'content' : [
          {
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": currentTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "Check out `All Requests` in the menu (the three horizontal lines) to see existing requests",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          },
					{
            "attachment_name": null,
            "id": "1",
            "message_id": "1",
            "thread_id": "-1",
            "date": currentTime.toISOString(),
            "sender": "555-5555",
            "recipients": [
              "867-5309"
            ],
            "attachments": 0,
            "priority": null,
            "body": "That's probably a great place to start.",
            "initials": 'Txt',
            "name": 'Txtifier.com',
            "is_sender": false
          },
        ],
        'meta' : {
          'initials' : 'Txt',
          'name' : 'Txtifier.com',
          'shows_timestamp' : false,
          'view_as' : [
            '867-5309'
          ]
        }
      },
		]
	}

  return (
    <Grid class="messages_root">
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          Publishing Public Records for All
        </Typography>
        <Typography variant="body2" component="p">
          Txtifier focuses on providing, formatting, and cross-referencing public records text messages in order to help improve government transparancy.
        </Typography>
      </CardContent>
      <CardActions>
        <Link to="/requests/" color="inherit">
					<Button size="small">Requests</Button>
				</Link>
        <Link to="/search/" color="inherit">
					<Button size="small">Person Search</Button>
				</Link>
        <Link to="/textsearch/" color="inherit">
					<Button size="small">Text Search</Button>
				</Link>
        <Link to="/about/" color="inherit">
					<Button size="small">About</Button>
				</Link>
      </CardActions>
    </Card>
		<Conversation threadData={data} name={"How does this site work?"} id={-1} />
		</Grid>
  );
}

