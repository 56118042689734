import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Contribute() {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          About
        </Typography>
        <Typography variant="body" component="p">
          Txtifier is looking for your help.  If you have something you wish to offer, please contact me at twitter: <a href="https://twitter.com/mikepkes">@mikepkes</a>
        </Typography>
        <br></br>
        <Typography variant="body2" component="p">
          Names are associated by separate FOIA requests and community contributions.  Please report any inaccuracies.
        </Typography>
        <br></br>
        <Typography variant="body2" component="p">
          Created and Maintained by Michael Kessler, Original Concept by Alan Kessler, Contributions from Nicole and Patrick
        </Typography>
      </CardContent>
    </Card>
  );
}

