import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


// MUI
import { makeStyles, withStyles } from '@mui/styles';

// MUI Components
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// MUI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';



const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%',
  }
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, description, date_published, query, hash, archive_hash, archive_size, message_count, request_id, visible) {
  return {
    name,
    description,
    date_published,
    request_id,
    visible,
    details: [
      { field: 'Original Request', value: query },
      { field: "Message Count", value: message_count},
      { field: 'Messages Hash', value: hash },
      { field: 'Archive Download', value: archive_hash, link: archive_hash ? "https://api.txtifier.com/archives/" + archive_hash + ".zip" : null },
      //{ field: "Archive Size", value: archive_size }
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const storeLocation = function (event) {
		try {
			const url = event.view.navigation.currentEntry.url;
			const scroll_id = event.target.id;
			window.sessionStorage.setItem('scroll_history_' + url, scroll_id)
		}
		catch {
		}
	}

	var link_item = row.name;
	if (row.visible === true) {
		link_item = <Link id={"request_" + row.request_id} to={"/request/" + row.request_id} onClick={storeLocation}>{row.name}</Link>
	}

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
					{link_item}

        </TableCell>
        <TableCell align="left">{row.description}</TableCell>
        <TableCell align="right">{row.date_published}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box px={12}>
              <Table size="small" aria-label="details">
                <TableBody>
                  {row.details.map((detailsRow) => (
                    <TableRow key={detailsRow.field}>
                      <TableCell component="th" scope="row">
                        {detailsRow.field}
                      </TableCell>
                        {detailsRow.link && <TableCell><a href={detailsRow.link}>{detailsRow.value}</a></TableCell>}
                        {(!detailsRow.link) && <TableCell>{detailsRow.value}</TableCell>}   
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string.isRequired,
    date_published: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.string,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};



export default function CollapsibleTable(props) {
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    // Update the document title using the browser API

    const url = window.location.href;
		var scroll_id = window.sessionStorage.getItem('scroll_history_' + url)
		if (scroll_id) {
			var item = document.getElementById(scroll_id)		
			if (item) {
				item.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
			}
		}

  });

  var buildRows = () => {
    var api_request = new XMLHttpRequest();
    api_request.addEventListener('load', function () {
      var request_return_value = api_request.status === 200 ? JSON.parse(this.responseText) : { 'data': [] };

      var tRows = [];
      for (const element of request_return_value.data) {
        tRows.push(
            createData(
                element.name,
                element.description,
                (new Date(element.created_at)).toLocaleString('en-US'),
                element.query,
                element.hash,
                element.archive_hash,
                element.size,
                element.message_count,
                element.id,
								element.visible
                )
        );
      }

      setRows(tRows);
    });
    api_request.open('GET', 'https://api.txtifier.com/v1/requests');
    api_request.send();
  };

  useEffect(() => {
    buildRows();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Request Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="right">Date Published</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name + "_" + row.request_id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export const RequestsTable = withStyles(styles)(CollapsibleTable);
