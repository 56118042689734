import React, { useState, useEffect } from 'react';

// Material UI
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LinearProgress from '@mui/material/LinearProgress';

// Local
import Conversation from './Conversation';



export default function Thread(props) {

  const [threads, setThreads] = useState([]);

  const threadId = props.match.params.threadId;

  function getConversationMessages() {
    var api_request = new XMLHttpRequest();
    api_request.addEventListener('load', function () {
      const results = (api_request.status === 200 ? JSON.parse(this.responseText) : { 'data': {} });
      setThreads(results.threads);
    });
    api_request.open('GET', `https://api.txtifier.com/v1/messages/thread/${threadId}?group=1`);
    api_request.send(null);
  }

  useEffect(() => {
    getConversationMessages();
  }, []);

  return (
    <Grid className="messages_root">
      {threads ? Object.keys(threads).map((thread) => {
        return (
          <Card>
            <Conversation threadData={threads[thread]} name={thread} id={thread} />
          </Card>
        );
      }) : <LinearProgress/>}
    </Grid>
  );
}

