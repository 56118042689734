
// Material UI
import Grid from "@mui/material/Grid";
import ContactsIcon from '@mui/icons-material/Contacts';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

// Local
import '../css/Message.css';
import { PersonIcon } from './PersonIcon';

function MessageBubbles(props) {
  const formattedMessages = props.messageTexts.map((message) =>
    <p class={props.class} id={"message_id_" +message.message_id}>{message.body}</p>
  );
  return (
    <div type="message">
      {formattedMessages}
    </div>
  );
}

function prepareInitials(obj, number) {
	if (obj === undefined) {
		if (number == null) {
			return "?";
		}
		return number.slice(-2);
	}
	else {
		return obj;
	}
}

export default function MessageGroup(props) {
  // const ref = React.useRef();

  const messageClass = props.owner ? "send" : "receive";
  const iconClass = messageClass + "-icon";
  const rowJustify = props.owner ? "flex-end" : "flex-start";

  const messageList = props.messages ? props.messages.map((message) => {
		if (message.attachment_name != null) {
			var url = "https://api.txtifier.com/attachments/" + message.id + "/" + message.attachment_name
			if (message.attachment_name.endsWith(".vcf")) {
				return {"body":<div><a href={url}><ContactsIcon/></a></div>, "message_id":message.message_id};
			}
			else if (/(?:mp3)|(?:amr)$/.test(message.attachment_name)) {
				return {"body":<div><a href={url}><RecordVoiceOverIcon/></a></div>, "message_id":message.message_id};
			}
			else{
				return {"body":<div>{message.body} <a href={url}><img src={url} class="img_attachment"/></a></div>, "message_id":message.message_id};
			}
		}
    return {"body":message.body, "message_id":message.message_id};
  }) : [];

  const personIcon = (
    <PersonIcon
      className={iconClass}
			info ={props.name ? props.name + " (" + props.sender + ")" : props.sender}
			initials={prepareInitials(props.initials, props.sender)}

      owner={props.owner}
			number={props.sender}
			name={props.name}
			message_context_link={"/thread/" + props.thread_id + "?view_as=" + props.sender}
			message_id={props.messages[0].message_id}
    />
  );

  return (
    <Grid
      container
      direction="column"
    >
      <div class="dtheader">{props.date}</div>
      <Grid
        container
        direction="row"
        alignItems="flex-end"
				wrap="nowrap"
        justifyContent={rowJustify}
        spacing={0}
      >
        {props.owner ? null : (
          <Grid item>
            {personIcon}
          </Grid>
        )}
        <Grid item>
          <MessageBubbles class={messageClass} messageTexts={messageList}/>
        </Grid>
        {props.owner ? (
          <Grid item >
            {personIcon}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
