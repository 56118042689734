import React from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function DateAndTimePickers(props) {
  const classes = useStyles();

	const [loading, setLoading] = React.useState(false);
	const [count, setCount] = React.useState(0);
	const [fromDate, setFromDate] = React.useState('2019-01-01T09:00');
	const [toDate, setToDate] = React.useState('2022-12-31T18:00');
	const [inFlight, setInFlight] = React.useState(null);


	const getCount = (from_date, to_date) => {

			if (inFlight != null) {
				console.log(inFlight);
				inFlight.abort();
				setInFlight(null);
				
			}
			setLoading(true);

			var api_request = new XMLHttpRequest();
			api_request.addEventListener('load', function () {
				var request_return_value = api_request.status === 200 ? JSON.parse(this.responseText) : { 'count' : 0 };
				console.log(request_return_value);
				setCount(request_return_value['count']);
				setLoading(false);
				setInFlight(null);
			});

			api_request.open('GET', 'https://api.txtifier.com/v1/messages/count/' + from_date + '/' + to_date);
			api_request.send();
			setInFlight(api_request);
	};

	const datetime_changed_from = function (e) {
		var newValue = e.target.value;
		setFromDate(newValue);
		getCount(newValue, toDate);
	}

	const datetime_changed_to = function (e) {
		var newValue = e.target.value;
		setToDate(fromDate, newValue);
		getCount();
	}

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label="Start Date"
        type="datetime-local"
        defaultValue="2019-01-01T09:00"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
				onChange={datetime_changed_from}
      />

      <TextField
        id="datetime-local"
        label="End Date"
        type="datetime-local"
        defaultValue="2022-12-31T18:00"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
				onChange={datetime_changed_to}
      />
			{loading && <CircularProgress />}
			{(!loading && count > 0 ) && <Button variant="contained">Found {count} messages.</Button>}
    </form>
  );
}
