import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
				<ListItem button key="Home">
					<Link to="/" color="inherit">
						<ListItemIcon> <MenuIcon/> </ListItemIcon>
						Home
					</Link>
				</ListItem>
				<Divider />
				<ListItem button key="Requests">
					<Link to="/requests/" color="inherit">
						<ListItemIcon> <MenuIcon/> </ListItemIcon>
						All Requests
					</Link>
				</ListItem>
				<ListItem button key="Person Search">
					<Link to="/search/" color="inherit">
						<ListItemIcon> <MenuIcon/> </ListItemIcon>
						Person Search
					</Link>
				</ListItem>
        <ListItem button key="Text Search">
					<Link to="/textsearch/" color="inherit">
						<ListItemIcon> <MenuIcon/> </ListItemIcon>
						Text Search
					</Link>
				</ListItem>
			{/*
				<ListItem button key="Dates">
					<Link to="/range/" color="inherit">
						<ListItemIcon> <MenuIcon/> </ListItemIcon>
						Dates
					</Link>
				</ListItem>
			*/}
			</List>
      <Divider />
      <List>
				{/*
				<ListItem button key="About">
					<Link to="/" color="inherit">
						<ListItemIcon> <MenuIcon/> </ListItemIcon>
						About
					</Link>
				</ListItem>
				*/}
				<ListItem button key="Contribute">
					<Link to="/about/" color="inherit">
					<ListItemIcon> <MenuIcon/> </ListItemIcon>
					About
					</Link>
				</ListItem>
			</List>
			
    </div>
  );

  return (
      <AppBar position="sticky">
        <Toolbar>
					<React.Fragment key='left'>
          <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer('left', true)}
              size="large"><MenuIcon /></IconButton>
						<Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
							{list('left')}

						</Drawer>
          <Typography variant="h6" className={classes.title}>
            Txtifier
          </Typography>
        </React.Fragment>
				</Toolbar>
      </AppBar>
  );
}
