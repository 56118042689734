import { Filter } from '@mui/icons-material';
import * as React from 'react';


import { Box, Typography } from '@mui/material';
import { TextField, Button, Table, TableRow, TableCell } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { PersonIcon } from '../../PersonIcon';
import { Link } from 'react-router-dom';

const interleave = (arr, thing) => [].concat(...arr.map(n => [n, thing])).slice(0, -1)

const TextSearchFilter = (props) => {
    //const [text, setText] = React.useState(props.text || '')
    const [results, setResults] = React.useState([])

    var text = ""
    const handleTextChange = ((event) => {
        //setText(event.target.value);
        text = event.target.value;
    });

    const handleSearch = ((event) => {
        event.preventDefault();
        //setText(event.target.value)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "query": text
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://api.txtifier.com/v1/messages/text_search/search", requestOptions)
        .then(response => response.json())
        .then(result => {setResults(result.data)})
        .catch(error => console.log('error', error));

    });

    return (
        <>
        <Box sx={{ flexShrink: 1, p:2}} >
            <form onSubmit={handleSearch}>
            <FormControl fullWidth>
                <TextField label="Search" display="inline" onChange={handleTextChange}/>
                <Button onClick={handleSearch} display="inline">Search</Button>
            </FormControl>
            </form>
        </Box>
        <Table>
            {results.map((r) => {
                return <TableRow key={r.message_id}>
                    <TableCell><Link to={"/thread/" + r.thread_id + "?view_as=" + r.sender + "&focus=" + r.message_id}>{r.message_id}</Link></TableCell>
                    <TableCell>
                        <PersonIcon
                            message_context_link = {"/thread/" + r.thead_id + "?view_as=" + r.sender + "&focus=" + r.message_id}
                            message_id = {r.message_id}
                            name={r.name}
                            number={r.sender}
                            initials={r.initials ? r.initials : r.sender.slice(-2)}
                            info={r.name?r.name + " (" + r.sender + ")" : r.sender}
                        />
                    </TableCell>
                    <TableCell>
                        
                        {   
                            r.headline.split(/[<][/]?b[>]/).map((v, i, a) => {
                                if (a.length === 1) {
                                    return v;
                                }
                                if (i == a.length-1) {
                                    return v;
                                }
                                if (i % 2) {
                                    return  <b>{v}</b>;
                                }
                                return v;
                            })
                        }
                    </TableCell>
                </TableRow>
            })}
        </Table>
        </>
    )
};

export default TextSearchFilter;