import React, { useState, useEffect } from "react";

// Material UI
import AvatarGroup from '@mui/material/AvatarGroup';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Link } from 'react-router-dom';




// Local
import MessageGroup from "./MessageGroup";
import { PersonIcon } from "./PersonIcon";

const queryString = require('query-string');

export default function Conversation(props) {
  // const messageGroups = [];

  function getSender(messageGroup) {
    // Message Groups are organized by sender, so
    // sender value is the same for all messages
    // in a Message Group.

    return messageGroup ? messageGroup.content[0].sender : "";
  }

  function getStartDate(messageGroup) {
    if (!messageGroup) return;
    return new Date(messageGroup.content[0].date).toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  function getDate(messageGroup) {
    return new Date(messageGroup.content[0].date).toLocaleDateString("en-US", {
      month: "numeric",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });
  }



	function prepareName(obj, number, include_number=true) {
		if (obj === null) {
			return number;
		}
		else {
			var name = obj;
			if (include_number) {
				name += " (" + number + ")";
			}
			return name;
		}
  }

  function getParticipantHeader(participants) {
    if (!participants) return;
    var p = participants.map((p) => {return p[0] ? p[0] : p[2]})
      .filter((p) => {
        return p !== null;
      });

			return p.join(', ');
  }

	function prepareInitials(obj, number) {
		if (obj === null) {
			if (number === null) {
				return '?';
			}
			return number.slice(-2);
		}
		else {
			return obj;
		}
  }

  let hash = window.location.hash.split('?').pop();
  const parsedHash = queryString.parse(hash);
  let requested_view_as = parsedHash['view_as'];
  let focus = parsedHash['focus'];

  if (requested_view_as === undefined) {  
    requested_view_as = props.threadData.messages[0]['meta']['view_as']
    if (requested_view_as === undefined || requested_view_as === null) {
      requested_view_as = [getSender(props.threadData.messages[0])];
    }
  }
  else {
    requested_view_as = requested_view_as.split(',');
  }

  const [viewAs, setViewAs] = useState(requested_view_as);
  const [assignments, setAssignments] = useState([]);
  const [messageGroups, setMessageGroups] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [threadId, setThreadId] = useState([]);

  useEffect(() => {
    setMessageGroups(props.threadData.messages);
    setViewAs(requested_view_as);
    setParticipants(props.threadData.participants);
		setAssignments(props.assignmentData);
    setThreadId(props.id)

  }, [props]);

	useEffect(() => {
    if (focus) {
      var item = document.getElementById("message_id_" + focus)
      if (item) {
        item.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
      }
    }
	});


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewThread = () => {
    setAnchorEl(null);
  }

  return (
    // <Grid>
    // </Grid>
    <Card>
      <CardHeader
        avatar={
          <AvatarGroup>
            {participants
              ? participants.map((participant) => {
                  if (participant && participant[2]) {
                    return (
                      <PersonIcon
												name={participant[0] ? participant[0] : null}
												number={participant[2]}
                        info={prepareName(participant[0], participant[2])}
                        initials={participant[1] ? participant[1] : prepareInitials(participant[0], participant[2])}
                        owner={viewAs.includes(participant[0])}
                      />
                    );
                  }
                })
              : null}
          </AvatarGroup>
        }
        action={
          <div>
          <IconButton aria-label="settings" onClick={handleClick} size="large">
            <MoreVertIcon />
          </IconButton>
          <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          >

          <MenuItem onClick={viewThread} component={Link} to={'/thread/' + threadId + "?view_as=" + viewAs.join(',')}>
            
            View Thread Across All Requests
            
          </MenuItem>
          </Menu>
          </div>
        }
        title={getParticipantHeader(participants)}
        subheader={getStartDate(messageGroups[0])}
      />
      <CardContent variant="outlined">
        {messageGroups.map((messageGroup) => {
          const sender = getSender(messageGroup);
          return (
            <MessageGroup
              messages={messageGroup.content}
              assignments={assignments}
              owner={messageGroup.meta.view_as ? messageGroup.meta.view_as.includes(sender) : viewAs.includes(sender)}
              sender={sender}
              date={
                messageGroup.meta.shows_timestamp ? getDate(messageGroup) : ""
              }
							name={messageGroup.meta.name}
							initials={prepareInitials(messageGroup.meta.initials, sender)}
							thread_id={threadId}
            />
          );
        })}
        
      </CardContent>
    </Card>
  );
}
