import * as React from 'react';

import { Box } from '@mui/material';
import { TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PhoneIcon from '@mui/icons-material/Phone';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


export default function ParticipantSearch(props) {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const optionEqual = (left, right) => {
        return (left.key == right.key);
    }


    const itemChanged = (event, values, reason) => {
        //console.log([event, value, reason]);

        if (values.length === 0) {
            props.threadsCallback({'threads':[]});
            return;
        }

        var v = [];
        values.forEach((vv) => {
            var r = {
                "rule": "any",
                "values": vv.numbers
            }
            v.push(r);
        })

        var rule = {
            "rule": "all",
            "values": v
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(rule);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

        fetch("https://api.txtifier.com/v1/messages/thread_rules/search", requestOptions)
          .then(response => {props.threadsLoadingCallback(); return response.json(); })
          .then(result => props.threadsCallback(result))
          .catch(error => console.log('error', error));

    }

    React.useEffect(() => {
        if (props.initialFilters) {

            itemChanged(null, [{'numbers':[props.initialFilters]}], null);
        }
    }, []);

    const inputChanged = (event, newInputValue) => {
        var url = new URL('https://api.txtifier.com/v1/people/search_grouped');
        url.searchParams.set('query', newInputValue);
        fetch(url).then(
            (response) => { return response.json()}
        ).then(
            (data) => {
                const items = data.data.map((value) => {
                    var icon = <QuestionMarkIcon/>

                    if ( !isNaN(value.numbers[0]) && value.numbers[0].length == 10 ) {
                        icon = <PhoneIcon/>
                    }

                    if (value.name === null) {
                        return {
                            'name' : value.numbers.join(','),
                            'icon' : icon,
                            'numbers' : value.numbers,
                            'key'  : value.numbers[0]
                        }
                    }


                    return {
                        'name': value.name + " (" + value.numbers.join( ',') + ")",
                        'icon': icon,
                        'numbers': value.numbers,
                        'key': value.group
                    };
                });
                setOptions(items); 
            }
        );
    };

    return (
        <Autocomplete
          multiple
          id="tags-standard"
          options={options}
          getOptionLabel={(option) => option.key}
          filterOptions={(x) => x}
          onInputChange={inputChanged}
          onChange={itemChanged}
          isOptionEqualToValue={optionEqual}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Participants"
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.icon}
                <Box>{option.name}</Box>
            </Box>

          )}
        />
    );
  }