import React from 'react';
// import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

// Local
import './css/App.css';
import DateAndTimePickers from './Components/DateRange.js';
import Landing from './Components/Landing.js';
import Contribute from './Components/Contribute.js';

import { Container, Box } from '@mui/material';
import { RequestsTable } from './Components/RequestsTable';
import Request from './Components/Request';
import Thread from './Components/Thread';
import Search from './Components/Search';
import TemporaryDrawer from './Components/Navigation';
import TextSearchFilter from './Components/Search/Filters/TextSearch';


function RequestsList() {
  return (
		<Container>
			<RequestsTable />
		</Container>
  );
}

export default function App() {
  const ref = React.useRef();
  return (
    <div className="App">
      <Router>
			<TemporaryDrawer/>
        <Switch>
					<Route path="/requests/" component={RequestsList} />
          <Route path="/request/:requestId" component={Request} />
          <Route path="/thread/:threadId" component={Thread} />
					<Route path="/search/:searchString" component={Search} />
					<Route path="/search/" component={Search} />
          <Route path="/textsearch/" component={TextSearchFilter} />
          <Route path="/textsearch/:searchString" component={TextSearchFilter} />
					<Route path="/range/" component={DateAndTimePickers} />
					<Route path="/about/" component={Contribute} />
          <Route path="/" component={Landing} />
        </Switch>
      </Router>
    </div>
  );
}
