import React, { useState } from 'react';

import FilterAddMenu from './Search/FilterMenu';
import ParticipantSearch from './Search/Filters/ParticipantSearch';

import { SearchThreadResultsTable } from './SearchThreadResults';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import ThreadSearchResults from './Search/ThreadSearchResults';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));

export default function Search(props) {
	const [filters, setFilters] = useState([]);

	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(0);

	const threadsFound = ((threadData) => {
		console.log(threadData);
		
		var rows = Object.keys(threadData.threads).map((t)=> {
				const r = threadData.threads[t];
				return [t, r.map((c) => {
					return [c.number, c.name, c.initials];
				})];
			})
		setResults(rows);
		setLoading(0);
	});
	
	const threadsLoading = () => {
		console.log("loading thread data...");
		setLoading(1);
	};

	return (
	<>
	<Stack spacing={2}>	
		{/*<Item>
				<Typography variant="h5" component="h2">
					Search Records
					<FilterAddMenu/>
				</Typography>
	</Item>*/}
		<Item>
			<ParticipantSearch
				threadsLoadingCallback={threadsLoading}
				threadsCallback={threadsFound}
				initialFilters={props.match.params.searchString}
			/>
		</Item>
	</Stack>
	<ThreadSearchResults
		rows = {results}
		loading = {loading}
	/>
	</>
	)
}

