import * as React from 'react';

import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import { PersonIcon } from '../PersonIcon';

export default function ThreadSearchResults(props) {

    return (
        <>{props.loading ? <LinearProgress/> : 
        <Table>
            {props.rows.length ? <TableHead><TableCell>Thread ID</TableCell><TableCell>Participants</TableCell></TableHead>
            : <TableRow><TableCell>No Results</TableCell></TableRow>
            }
            {
                props.rows.map(r => {
                    return <TableRow>
                        <TableCell> <Link to={"/thread/" + r[0] + "?view_as=" + r[1][0][0]}>{r[0]}</Link></TableCell>
                        <TableCell>
                            <Box sx={{ display: 'inline'}}>
                        {r[1].map(p => {
                            return <Box sx={{ display: 'inline-flex', m: 0.5 }}>
                                <PersonIcon
                                thread_link={"/thread/" + r[0]}
                                info={p[1]? p[1] + " (" + p[0] + ")" : p[0]}
                                number={p[0]}
                                name={p[1]}
                                initials={p[2] ? p[2] : p[0].slice(-2)}
                            />
                            </Box>
                        })}
                        </Box>
                        </TableCell>
                        
                        </TableRow>
                })
            }
        </Table>
        }
        </>
    )
}