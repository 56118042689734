import React from 'react';

// Material UI

import Avatar from "@mui/material/Avatar";
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';

// Local
import '../css/Message.css';

export function PersonIcon(props) {
  const ref = React.useRef();

	const [alertOpen, setAlertOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
	const [alertMsg, setAlertMsg] = React.useState("Copied to Clipboard")


	const [showToolTip, setShowToolTip] = React.useState(false)

  const handleAlert = (success) => {
		if (success) {
			setAlertMsg("Copied to Clipboard");
		}
		else {
			setAlertMsg("Failed to Copy to Clipboard");
		}
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
		setShowToolTip(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewThread = () => {
    setAnchorEl(null);
  }

	const copyNumber = () => {
		navigator.clipboard.writeText(props.number).then(() => {
			/* Resolved - text copied to clipboard */
			handleAlert(true);
		},() => {
			handleAlert(false);
			/* Rejected - clipboard failed */
		});
	}

	const copyLink = () => {
		const url = new URL(window.location.href);
		url.searchParams.set("focus", props.message_id);
		navigator.clipboard.writeText(url).then(() => {
			/* Resolved - text copied to clipboard */
			handleAlert(true);
		},() => {
			/* Rejected - clipboard failed */
			handleAlert(false);
		});
	}

	const copyName = () => {
		navigator.clipboard.writeText(props.name).then(() => {
			/* Resolved - text copied to clipboard */
			handleAlert(true);
		},() => {
			/* Rejected - clipboard failed */
			handleAlert(false);
		});
	}

  // console.log("PERSON PROPS", props);
  return (
	<div>
	 <ClickAwayListener onClickAway={handleClose}>
    <Tooltip ref={ref} title={props.info} aria-label="icon"
				open={showToolTip} 
        disableHoverListener
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
			{...props}>
      <Avatar className={props.owner ? 'send-avatar' : ''} onClick={handleClick} onClose={handleClose}>
        {props.initials}
				{props.number && 
          <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          >

					<MenuItem disabled>{props.info}</MenuItem>
					<Divider />

					{props.thread_link &&
							<MenuItem onClick={viewThread} component={Link} to={props.thread_link + "?view_as=" + props.number}>
								See thread as {props.info}
							</MenuItem>
					}

					{props.message_context_link &&
						<MenuItem onClick={viewThread} component={Link} to={props.message_context_link + (props.message_id ? '&focus=' + props.message_id : '')}>
							See message in full context
						</MenuItem>
					}
          <MenuItem onClick={viewThread} component={Link} to={'/search/' + props.number}>
						See threads including this number
          </MenuItem>
					<Divider />
					{false && props.message_id && 
						<MenuItem onClick={copyLink} >
							Copy link to this message
						</MenuItem>
					}
					{props.name && 
						<MenuItem onClick={copyName} >
							Copy name ({props.name})
						</MenuItem>
					}
          <MenuItem onClick={copyNumber} >
						Copy number ({props.number})
          </MenuItem>
          </Menu>
				}
      </Avatar>
      {/* </ThemeProvider> */}
    </Tooltip>
	 </ClickAwayListener>
	 <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alertOpen}
        autoHideDuration={1800}
        onClose={handleAlertClose}
        message={alertMsg}
      />
	</div>
  );
}
